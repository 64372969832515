<template>
  <div class="mx-2">
    <p class="title_caption">{{ label }}</p>
    <p
      :class="
        `sales_value mr-3 ${
          value === null || isNaN(value)
            ? ''
            : value > 0
            ? 'green'
            : value === 0
            ? ''
            : 'red'
        }`
      "
    >
      {{
        value === null || isNaN(value)
          ? "-"
          : `${value > 0 ? "+" : value === 0 ? "" : "-"}${numberFormatEs(
              Math.abs(value)
            )}%`
      }}
    </p>
    <p class="title_caption">
      {{
        valueType === "SALES"
          ? displayAmount !== null && !isNaN(displayAmount) && parseFloat(displayAmount) !== 0
            ? currency_format(displayAmount)
            : ""
          : formatKG(displayAmount)
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: "sessionPosStatistics",
  props: ["label", "value", "valueType", "displayAmount"],
  data: function() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    formatKG(amount) {
      let ret = "";
      if (amount !== null && !isNaN(amount) && amount !== 0)
        ret = `${this.numberFormatEs(amount)} Kg`;
      return ret;
    },
    init() {}
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
