<template>
  <div>
    <DialogPDF ref="dialogPDF" :modalref="`dialogPDF`" />
    <v-layout v-resize="onResize" column>
      <v-data-table
        :headers="promosHeaders"
        :items="currentPromosForSelectedBrand"
        class="elevation-1"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [10, 50, 100]
        }"
        :hide-default-footer="true"
        disable-pagination
        :item-class="row_classes"
        :mobile-breakpoint="700"
      >
        <template v-slot:item.promosType="{ item }"
          >{{ promosTypeList[item.promosType] }}
        </template>
        <template v-slot:item.place="{ item }"
          >{{ placeTypeList[item.place] }}
        </template>
        <template v-slot:item.material="{ item }"
          >{{ item.material?.name }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.statusKey)" dark>
            {{ item.status }}
          </v-chip>
          <v-chip
            :color="`red`"
            @click="onClickPDF(item)"
            v-if="item?.promosPdfFile"
            dark
            class="ml-2"
          >
            PDF
          </v-chip>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import DialogPDF from "./DialogPDF.vue";

export default {
  name: "promosTable",
  props: ["currentPromosForSelectedBrand", "promosTypeList", "placeTypeList"],
  components: {
    DialogPDF
  },
  data: function() {
    return {
      isMobile: false,
      shouldCheckFullFilledCols: [
        "price",
        "place",
        // "space",
        "folleto"
      ]
    };
  },
  watch: {},
  computed: {
    checkIsFullFilledFiveFields() {
      let isFullFilled = true;
      // price, place, CM(space), material, folleto
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        for (const row of this.currentPromosForSelectedBrand) {
          for (const checkKey of this.shouldCheckFullFilledCols) {
            if (Object.keys(row).includes(checkKey) && row[checkKey] === null) {
              isFullFilled = false;
            }
          }
        }
      }
      return isFullFilled;
    },
    promosHeaders() {
      let columns = [
        {
          text: `${this.$t("brands.description")}`,
          value: "description",
          width: 170
        },
        {
          text: `${this.$t("Date")} ${this.$t("consumer")}`,
          value: "dateLabel",
          width: 160
        }
      ];
      let firstFullFillRequiredCols = [
        {
          text: `${this.$t("orderDate")}`,
          value: "orderDateLabel",
          width: 160
        }
      ];
      if (this.checkRequiredFields(["orderDate"])) {
        columns = [...columns, ...firstFullFillRequiredCols];
      }
      if (this.checkFullfilledColumns("productsLabel")) {
        columns = [
          ...columns,
          ...[
            {
              text: `${this.$t("Product")}`,
              value: "productsLabel",
              width: 160
            }
          ]
        ];
      }
      if (this.checkFullfilledColumns("material")) {
        columns = [
          ...columns,
          {
            text: `${this.$t("Material")}`,
            value: "material",
            width: 160
          }
        ];
      }
      let secondFullFillRequiredCols = [
        {
          text: `${this.$t("Type")} Promo`,
          value: "promosType",
          width: 120
        },
        {
          text: `${this.$t("Price")}`,
          value: "price",
          width: 120
        },
        {
          text: `${this.$t("Place")}`,
          value: "place",
          width: 120
        },
        // {
        //   text: `CM`,
        //   value: "space",
        //   // width: 150,
        // },
        {
          text: `${this.$t("Folleto")}`,
          value: "folleto",
          width: 120
        }
      ];
      if (
        this.checkRequiredFields(["promosType", "price", "place", "folleto"])
      ) {
        columns = [...columns, ...secondFullFillRequiredCols];
      }
      columns = [
        ...columns,
        {
          text: `${this.$t("salesforce.status")}`,
          value: "status",
          width: 170
        }
      ];
      return columns;
    }
  },
  methods: {
    checkRequiredFields(fullFillRequiredCols) {
      let isFullFilled = true;
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        for (const row of this.currentPromosForSelectedBrand) {
          for (const checkKey of fullFillRequiredCols) {
            if (
              checkKey === "orderDate" &&
              Object.keys(row).includes("orderDateLabel")
            ) {
              if (!row?.startOrderDate || !row?.endOrderDate) {
                isFullFilled = false;
              }
            } else if (
              Object.keys(row).includes(checkKey) &&
              row[checkKey] === null
            ) {
              isFullFilled = false;
            }
          }
        }
      }
      return isFullFilled;
    },
    checkFullfilledColumns(fullFillRequiredCol) {
      let isFullFilled = false;
      if (
        this.currentPromosForSelectedBrand &&
        this.currentPromosForSelectedBrand.length > 0
      ) {
        const recordCount = this.currentPromosForSelectedBrand.length;
        let notFullFilledRecordCount = 0;
        for (const row of this.currentPromosForSelectedBrand) {
          if (
            fullFillRequiredCol === "orderDate" &&
            Object.keys(row).includes("orderDateLabel")
          ) {
            if (!row?.startOrderDate || !row?.endOrderDate) {
              notFullFilledRecordCount++;
            }
          } else if (
            Object.keys(row).includes(fullFillRequiredCol) &&
            (row[fullFillRequiredCol] === null ||
              row[fullFillRequiredCol] === "")
          ) {
            notFullFilledRecordCount++;
          }
        }
        if (notFullFilledRecordCount < recordCount) {
          isFullFilled = true;
        }
      }
      return isFullFilled;
    },
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
    row_classes(item) {
      if (item.statusKey === "PREPARE") {
        return "bg_orange";
      }
    },
    getColor(status) {
      if (status === "ACTIVE" || status === "PREPARE") {
        return "green";
      } else if (status === "PREPARE") {
        return "orange";
      }
      return "red";
    },
    onClickPDF(item) {
      if (item?.promosPdfFile) {
        let filePath = ApiService.getFileUri(item?.promosPdfFile);
        this.$refs.dialogPDF.showModal(filePath, "dialogPDF");
      } else {
        logError("No hay datos para mostrar.");
      }
    },
    async init() {}
  },
  async updated() {},
  async mounted() {}
};
</script>
<style>
/* each row block css in mobile*/
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  word-break: break-all;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
