<template>
  <div>
    <v-layout v-resize="onResize" column>
      <v-data-table
        :headers="tableHeaders"
        :items="promo_locals"
        class="elevation-1"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
        }"
        :hide-default-footer="true"
        disable-pagination
        :mobile-breakpoint="700"
      >
        <template v-slot:item.date="{ item }">
          {{ `${item.startDate} / ${item.endDate}` }}
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "promoLocalTable",
  props: ["promo_locals"],
  components: {},
  data: function() {
    return {
      isMobile: false,
    };
  },
  watch: {},
  computed: {
    tableHeaders() {
      let columns = [
        {
          text: `${this.$t("Start")} ${this.$t("End")} ${this.$t("Date")}`,
          value: "date",
          // width: 200,
        },
        {
          text: `${this.$t("Place")}`,
          value: "placeLabel",
          // width: 200,
        },
        {
          text: `${this.$t("Product")}`,
          value: "productsLabel",
          // width: 200,
        },
        {
          text: `${this.$t("comments")}`,
          value: "comments",
          // width: 200,
        },
      ];
      return columns;
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
    async init() {},
  },
  async updated() {},
  async mounted() {},
};
</script>
<style>
/* each row block css in mobile*/
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  margin: 10px;
  border: 1px solid #ededed;
  display: block;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  word-break: break-all;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
