<template>
  <div>
    <modal name="modal-add-message" :width="`90%`" :height="`auto`" scrollable>
      <v-overlay :value="isLoadingSpinner" :opacity="`0.5`"
        ><v-progress-circular
          indeterminate
          size="55"
          width="7"
          color="primary"
        ></v-progress-circular
      ></v-overlay>
      <v-form ref="formAddMessage" v-model="valid" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ dialogTitle }}</span>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <h6>{{ $t("Message") }}</h6>
                <vue-editor
                  v-model="formData.messageContents"
                  :editorOptions="vue2EditorOptionsWithEmojis"
                /> -->
                <v-textarea
                  v-model="formData.messageContents"
                  v-bind:label="$t('Message')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleSave()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

export default {
  name: "DialogAddMessage",
  components: { VueEditor },
  data: function() {
    return {
      messageCategory: "BRAND_MESSAGING",
      formData: {
        messageContents: ""
      },
      valid: true,
      requiredRules: [v => !!v || "Required"],

      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    dialogTitle() {
      let title = `${this.$t("Add")} ${this.$t("Message")}`;
      if (this.messageCategory === "INCIDENCE_MESSAGING") {
        title = `${this.$t("Add")} ${this.$t("Incidence")}`;
      }
      return title;
    }
  },
  methods: {
    /****** MODAL CONFIGURATION ******/
    showModal(messageCategory) {
      this.init(messageCategory);
      this.$modal.show("modal-add-message");
    },
    hideModal() {
      this.$modal.hide("modal-add-message");
    },
    async setIsLoadingSpinner(val) {
      this.isLoadingSpinner = val;
    },
    handleSave() {
      if (this.$refs.formAddMessage.validate()) {
        this.$emit("onClickSave", this.messageCategory, this.formData);
      }
    },

    /****** INITIALIZE ******/
    async init(messageCategory) {
      this.messageCategory = messageCategory;
      this.formData.messageContents = "";
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
