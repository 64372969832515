<template>
  <div>
    <modal name="modal-message-list" :width="`90%`" :height="`auto`" scrollable>
      <v-overlay :value="isLoadingSpinner" :opacity="`0.5`"
        ><v-progress-circular
          indeterminate
          size="55"
          width="7"
          color="primary"
        ></v-progress-circular
      ></v-overlay>
      <v-form ref="formEditMessage" v-model="valid" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ dialogTitle }}</span>
          </v-card-text>

          <v-card-text>
            <v-row class="text--primary">
              <v-col cols="12" sm="12" md="12">
                <template v-for="(messageItem, messageKey) in messageList">
                  <div :key="`message_dialog_item_${messageKey}`">
                    <v-divider></v-divider>
                    <div
                      class="message_item_header d-flex justify-space-between"
                    >
                      <h6 class="mb-0 d-flex align-center">
                        {{ messageItemTitle(messageItem) }}
                        <v-chip
                          v-if="messageCategory === 'INCIDENCE_MESSAGING'"
                          :color="mStatusColor(messageItem)"
                          small
                          text-color="white"
                          class="ml-3"
                        >
                          {{ mStatusLabel(messageItem) }}
                        </v-chip>
                      </h6>
                      <div>
                        <v-icon
                          medium
                          color="primary"
                          class="mr-2"
                          @click="onClickEdit(messageItem)"
                          v-if="editedId !== messageItem.id"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          medium
                          color="green"
                          class="mr-2"
                          @click="onClickSave(selected)"
                          v-if="editedId === messageItem.id"
                        >
                          mdi-content-save
                        </v-icon>
                        <v-icon
                          medium
                          color="red"
                          class="mr-2"
                          @click="onClickEditClose(messageItem)"
                          v-if="editedId === messageItem.id"
                        >
                          mdi-window-close
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="message_contents mt-4"
                      v-if="editedId !== messageItem.id"
                      v-html="messageItem.messageContents"
                    ></div>
                    <div class="message_contents" v-else>
                      <!-- <h6>{{ $t("Message") }}</h6>
                      <vue-editor
                        v-model="selected.messageContents"
                        :editorOptions="vue2EditorOptionsWithEmojis"
                      /> -->
                      <v-textarea
                        v-model="selected.messageContents"
                        v-bind:label="$t('Message')"
                      ></v-textarea>
                    </div>
                    <div v-if="messageItem.responsableComments">
                      <h6>
                        {{
                          `${$t("comments")} ${$t("expensesParentUserName")}`
                        }}
                      </h6>
                      <div
                        class="message_contents mt-4"
                        v-html="messageItem.responsableComments"
                      ></div>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

export default {
  name: "DialogMessageList",
  props: ["mStatus"],
  components: { VueEditor },
  data: function() {
    return {
      messageCategory: "BRAND_MESSAGING",
      posId: null,
      brandId: null,
      messageList: [],

      selected: null,
      editedId: null,
      originalData: null,
      formData: {
        messageContents: ""
      },
      valid: true,
      requiredRules: [v => !!v || "Required"],

      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    dialogTitle() {
      let title = `${this.$t("lastVisit")} ${this.$t("Overall")} ${this.$t(
        "comments"
      )}`;
      if (this.messageCategory === "INCIDENCE_MESSAGING") {
        title = `${this.$t("Incidence")}`;
      }
      return title;
    }
  },
  methods: {
    messageItemTitle(messageItem) {
      let title = this.formatDate(messageItem?.mDate);
      if (!_.isEmpty(messageItem?.usernameLabel)) {
        title += ` - ${messageItem?.usernameLabel}`;
      }
      return title;
    },
    async onClickSave(selected) {
      if (this.$refs.formEditMessage.validate()) {
        console.log(selected);
        const { id, messageContents } = selected;
        let formData = { id, messageContents };
        this.isLoadingSpinner = true;
        try {
          let editStatus = await ApiService.put(`worksession/editmessage`, {
            formData,
            messageCategory: this.messageCategory
          });
          if (editStatus && editStatus?.success) {
            logInfo(this.$t("Success"));
            this.onClose();
            this.$emit("reloadMessages");
          } else {
            logInfo(this.$t("Failed"));
            this.selected.messageContents = this.originalData.messageContents;
            this.onClose();
          }
        } catch (error) {}
        this.isLoadingSpinner = false;
      }
    },
    onClickEdit(messageItem) {
      this.selected = messageItem;
      this.editedId = messageItem.id;
      this.originalData = JSON.parse(JSON.stringify(messageItem));
    },
    onClose() {
      this.selected = null;
      this.editedId = null;
      this.originalData = null;
    },
    onClickEditClose(messageItem) {
      this.selected.messageContents = this.originalData.messageContents;
      this.onClose();
    },
    mStatusLabel(messageItem) {
      if (
        this.mStatus &&
        Object.keys(this.mStatus).includes(messageItem.mStatus)
      ) {
        return this.mStatus[messageItem.mStatus];
      }
      return "";
    },
    mStatusColor(messageItem) {
      let color = "primary";
      if (
        this.mStatus &&
        messageItem &&
        Object.keys(this.mStatus).includes(messageItem.mStatus)
      ) {
        switch (messageItem.mStatus) {
          case "PENDING":
            color = "red";
            break;

          default:
            color = "green";
            break;
        }
      }
      return color;
    },
    formatDate(mDate) {
      if (!mDate) return "";
      return moment(mDate).format("DD/MM/YYYY");
    },
    /****** MODAL CONFIGURATION ******/
    async showModal(messageCategory, posId, brandId) {
      await this.init(messageCategory, posId, brandId);
    },
    hideModal() {
      this.$modal.hide("modal-message-list");
    },

    /****** INITIALIZE ******/
    async init(messageCategory, posId, brandId) {
      this.messageCategory = messageCategory;
      this.posId = posId;
      this.brandId = brandId;
      this.formData.messageContents = "";
      try {
        let { messageList } = await ApiService.post(
          `worksession/getmessagelist/${posId}/${messageCategory}/${brandId}`
        );
        if (messageList) {
          this.messageList = messageList;
        }
        this.$modal.show("modal-message-list");
      } catch (error) {}
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
