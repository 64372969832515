<template>
  <div
    v-if="messageItem"
    class="messageItemLi"
    @click="onClickMessageItem(messageCategory, messageItem)"
  >
    <h6>
      {{ messageItemTitle(messageItem) }}
      <v-chip
        v-if="messageCategory === 'INCIDENCE_MESSAGING'"
        :color="mStatusColor"
        small
        text-color="white"
      >
        {{ mStatusLabel }}
      </v-chip>
    </h6>
    <div class="message_contents" v-html="messageItem.messageContents"></div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "MessageListItem",

  props: ["messageItem", "messageCategory", "mStatus"],

  components: {},

  watch: {},

  computed: {
    mStatusLabel() {
      if (
        this.mStatus &&
        this.messageItem &&
        Object.keys(this.mStatus).includes(this.messageItem.mStatus)
      ) {
        return this.mStatus[this.messageItem.mStatus];
      }
      return "";
    },
    mStatusColor() {
      let color = "primary";
      if (
        this.mStatus &&
        this.messageItem &&
        Object.keys(this.mStatus).includes(this.messageItem.mStatus)
      ) {
        switch (this.messageItem.mStatus) {
          case "PENDING":
            color = "red";
            break;

          default:
            color = "green";
            break;
        }
      }
      return color;
    }
  },

  data: function() {
    return {};
  },
  methods: {
    messageItemTitle(messageItem) {
      let title = this.formatDate(messageItem?.mDate);
      if (!_.isEmpty(messageItem?.usernameLabel)) {
        title += ` - ${messageItem?.usernameLabel}`;
      }
      return title;
    },
    formatDate(mDate) {
      if (!mDate) return "";
      return moment(mDate).format("DD/MM/YYYY");
    },
    onClickMessageItem(messageCategory, messageItem) {
      console.log("CLICK MESSAGE ITEM - ", messageCategory);
      this.$emit("onClickMessageItem", messageCategory, messageItem);
    }
  },

  async mounted() {}
};
</script>

<style>
.messageItemLi {
  padding: 0.5rem;
  background: #eee;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.messageItemLi p {
  margin: 0;
}
</style>
