var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2"},[_c('p',{staticClass:"title_caption"},[_vm._v(_vm._s(_vm.label))]),_c('p',{class:`sales_value mr-3 ${
        _vm.value === null || isNaN(_vm.value)
          ? ''
          : _vm.value > 0
          ? 'green'
          : _vm.value === 0
          ? ''
          : 'red'
      }`},[_vm._v(" "+_vm._s(_vm.value === null || isNaN(_vm.value) ? "-" : `${_vm.value > 0 ? "+" : _vm.value === 0 ? "" : "-"}${_vm.numberFormatEs( Math.abs(_vm.value) )}%`)+" ")]),_c('p',{staticClass:"title_caption"},[_vm._v(" "+_vm._s(_vm.valueType === "SALES" ? _vm.displayAmount !== null && !isNaN(_vm.displayAmount) && parseFloat(_vm.displayAmount) !== 0 ? _vm.currency_format(_vm.displayAmount) : "" : _vm.formatKG(_vm.displayAmount))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }