<template>
  <div>
    <div class="d-flex flex-column p-1 questionbox" v-if="question">
      <div class="question-label">
        <p class="px-1 m-0">
          {{ question.name }}
        </p>
      </div>
      <div
        :class="
          `d-flex justify-content-center question-content ${calcBackgroundColor}`
        "
      >
        <input
          :class="
            `survey-input-xs survey-input-number-xs ${
              this.isImportedData ? 'isImportedData' : ''
            }`
          "
          type="number"
          placeholder="0.00"
          required
          :value="inputValue"
          :readonly="isReadOnlyInput"
          @change="onChangeKPIValues"
        />
        <span class="ml-1 d-flex align-center">
          Obj : {{ importedObjective }}
        </span>
      </div>
    </div>
    <b-alert v-else class="p-2 m-0" variant="danger" show>
      Not implemented !
    </b-alert>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { QUESTION_TYPES } from "@/views/surveys/models";
import { mapGetters } from "vuex";

export default {
  name: "KPIQuestionItem",
  props: ["question", "readOnlyInputs", "fromPageCategory"],
  components: {},
  watch: {},

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", ["isEndedSession"]),
    isReadOnlyInput() {
      let ret = true;
      // if (this.fromPageCategory !== "route") {
      //   if (
      //     this.question &&
      //     ["SURVEY_DATA"].includes(this.question?.questionImportType)
      //   ) {
      //     ret = false;
      //   }
      // }
      return ret;
    },
    thisQuestionType() {
      let ret = null;
      if (
        this.question?.survey_question_type &&
        this.question?.survey_question_type?.type
      ) {
        ret = this.question?.survey_question_type.type;
      }
      return ret;
    },
    isKPIthisQuestion() {
      let ret = false;
      if (this.question?.isKPI) {
        return true;
      }
      return ret;
    },
    isImportedData() {
      let ret = false;
      if (["IMPORT_DATA"].includes(this.question.questionImportType))
        ret = true;
      return ret;
    },
    isAvailableImportedObjective() {
      let ret = false;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.NUMBER,
          QUESTION_TYPES.DECIMAL
        ].includes(this.thisQuestionType) &&
        this.isKPIthisQuestion &&
        this.isImportedData
      ) {
        ret = true;
      }
      return ret;
    },
    foundImportedAnswerItem() {
      return this.question?.foundImportedAnswerItem;
    },
    importedObjective() {
      return this.question?.sumOfImportedObjective;
    },
    inputValue() {
      return this.question?.sumOfValue;
    },
    calcBackgroundColor() {
      if (this.inputValue >= this.importedObjective) {
        return "back_green";
      }
      return "back_red";
    }
  },

  data: function() {
    return {};
  },
  methods: {
    onChangeKPIValues() {
      if (!this.isReadOnlyInput && this.question) {
        const { foundResponseSurveyQuestionItem } = this.question;
        console.log(
          "foundResponseSurveyQuestionItem - ",
          foundResponseSurveyQuestionItem
        );
        if (foundResponseSurveyQuestionItem) {
        }
      }
    }
  },

  async mounted() {}
};
</script>

<style>
input.survey-input-xs,
.survey-input,
.survey-textarea {
  border: 1px solid #222;
  padding: 5px;
}
.survey-textarea {
  width: 100%;
}
.question-label {
  text-align: center;
  background: #faebd7;
  padding: 0 10px;
  font-size: 15px;
  color: #000;
  border: 1px solid #888;
}
.question-content {
  border: 1px solid #888;
  border-top: none;
  padding: 8px;
}
.questionbox {
  max-width: 100%;
  min-width: 150px;
}
.question-content .v-input--selection-controls {
  margin-top: 0px;
}
.question-content .v-radio {
  margin: 0 !important;
}
.question-content .v-input--selection-controls .v-input__slot,
.question-content .v-input--selection-controls .v-radio {
  margin: 0 !important;
}
.survey-input-number-xs.isImportedData {
  background: #cfcfcf;
}
</style>
